import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ImageWithConfigFragment } from '@codegen/cmsUtils';
import { useQuery } from '@tanstack/react-query';
import { IconColourProp, ThemeResponsiveProp } from '@ui-v2/types/props';
import { IconType } from '@ui-v2/types/typography';
import { buildResponsiveValues } from '@ui-v2/utils/buildResponsiveValues';
import { retrieveThemeValues } from '@ui-v2/utils/retrieveThemeValues';
import { createCSSFilterFromHex } from '@ui-v2/utils/styleUtils';
import useGetIcon from '@utils/hooks/useGetIcon';

const DEFAULT_ICON_SIZE = 12;

export type IconProps = {
  className?: string;
  colour?: IconColourProp;
  height?: ThemeResponsiveProp<number>;
  iconCustom?: ImageWithConfigFragment | null;
  size?: ThemeResponsiveProp<number>;
  type: IconType | 'custom';
  width?: ThemeResponsiveProp<number>;
};

const Container = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'boundaryWidth' && prop !== 'boundaryHeight',
})<
  Omit<IconProps, 'height' | 'width' | 'color' | 'type' | 'src'> & {
    boundaryHeight: ThemeResponsiveProp<number>;
    boundaryWidth: ThemeResponsiveProp<number>;
  }
>(({ boundaryHeight, boundaryWidth, colour = 'icons.default', theme }) => [
  css`
    ${buildResponsiveValues({
      width: boundaryWidth,
      height: boundaryHeight,
    })};

    & > svg {
      ${buildResponsiveValues({
        width: boundaryWidth,
        height: boundaryHeight,
      })}
      ${createCSSFilterFromHex(
        retrieveThemeValues(theme.colours, colour) as unknown as string,
      )};
    }
  `,
]);

const fetchSVG = async (url?: string) => {
  if (!url) {
    return null;
  }

  const response = await fetch(url);
  const data = await response.text();

  return data.match(/fill="none"/)
    ? data.replace(/stroke=".*?"/g, 'stroke="#000"')
    : data
        .replace(/<defs>[\s\S]*?<\/defs>/g, '')
        .replace(/fill=".*?"/g, 'fill="#000"')
        .replace(/stroke=".*?"/g, 'stroke="#000"');
};

const Icon = ({
  height,
  iconCustom,
  size,
  type,
  width,
  ...props
}: IconProps) => {
  const definedWidth = width ?? size ?? DEFAULT_ICON_SIZE;
  const definedHeight = height ?? size ?? DEFAULT_ICON_SIZE;
  const getIcon = useGetIcon();
  const icon = type === 'custom' ? iconCustom : getIcon(type);

  const { data: svgData } = useQuery({
    queryKey: [icon?.asset.url] as string[],
    queryFn: () => fetchSVG(icon?.asset.url),
    enabled: !!icon?.asset.url,
  });

  if (!icon) {
    // eslint-disable-next-line no-console
    console.warn('Icon not found in Icon.tsx');

    return null;
  }

  if (!svgData) {
    return (
      <Container boundaryHeight={definedHeight} boundaryWidth={definedWidth} />
    );
  }

  return (
    <Container
      boundaryHeight={definedHeight}
      boundaryWidth={definedWidth}
      dangerouslySetInnerHTML={{ __html: svgData }}
      data-visual-test="removed"
      {...props}
    />
  );
};

export default Icon;
