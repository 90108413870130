import { ParsedUrlQuery } from 'querystring';
import { createContext, useContext, useEffect, useState } from 'react';
import { BookingStepFragment } from '@codegen/cmsUtils';
import { parseQueryStringToObject } from '@utils/queryUtils';
import {
  createSearchUrlFromBookingDeeplink,
  parseBookingDeeplink,
} from '@web/utils/booking/bookingUtils';
import useBookingConfig from './hooks/useBookingConfig';
import usePartnerRouter from './hooks/usePartnerRouter';
import usePartnerPassengersConfig from './hooks/usePassengersConfig';
import useVendors from './hooks/useVendors';
import { shouldShowSeatmapForVendors } from './utils/stepContextUtils';

export interface InTransition {
  next: boolean;
  previous: boolean;
}

export type Step = {
  activeStepIndex: number;
  firstBookingStepIndex: number;
  inTransition: InTransition;
  isFirstBookingStep: boolean;
  isLastStep: boolean;
  searchStep?: BookingStepFragment;
  setInTransition: (inTransition: InTransition) => void;
  shouldShowSeatmap: boolean;
  steps: BookingStepFragment[];
  triggerBookingFlowStepTransition: (values?: {
    as?: string;
    goToPreviousStep?: boolean;
    query?: ParsedUrlQuery;
    route?: string;
  }) => void;
};

const inTransitionInitalState = {
  next: false,
  previous: false,
};

const context = createContext<Step>({
  activeStepIndex: 0,
  steps: [],
  triggerBookingFlowStepTransition: () => {},
  inTransition: inTransitionInitalState,
  setInTransition: () => {},
  isLastStep: false,
  firstBookingStepIndex: 2,
  shouldShowSeatmap: true,
  isFirstBookingStep: true,
});

export default context;

export const StepProvider = ({ children }: { children: React.ReactNode }) => {
  const [inTransition, setInTransition] = useState<InTransition>(
    inTransitionInitalState,
  );

  const { passengerRules } = usePartnerPassengersConfig();

  const { prefetch, push, query, route } = usePartnerRouter();

  const { vendors } = useVendors({
    includeBookingData: true,
    iatas: parseBookingDeeplink(query, passengerRules)?.itineraryIatas ?? [],
  });

  const shouldShowSeatmap = shouldShowSeatmapForVendors(vendors);

  const { steps } = useBookingConfig();

  const filteredSteps =
    Object.keys(vendors).length > 0
      ? steps.filter((step) =>
          step.route.includes('seats') ? shouldShowSeatmap : true,
        )
      : steps;

  const activeStepIndex = filteredSteps.findIndex((step) => {
    if (step.route === '/') {
      return step.route === route;
    }

    return route.includes(step.route);
  });

  const firstBookingStepIndex = steps.findIndex(
    (step) => step.isFirstBookingStep,
  );

  const searchStep = filteredSteps.find((step) => step.isSearchStep);

  const triggerBookingFlowStepTransition = async (values?: {
    goToPreviousStep?: boolean;
    query?: ParsedUrlQuery;
    route?: string;
  }) => {
    setInTransition({
      next: Boolean(!values?.goToPreviousStep),
      previous: Boolean(values?.goToPreviousStep),
    });

    const nextStepIndex =
      activeStepIndex === -1
        ? firstBookingStepIndex + (values?.goToPreviousStep ? -1 : 1)
        : activeStepIndex + (values?.goToPreviousStep ? -1 : 1);

    const nextRoute = values?.route || filteredSteps[nextStepIndex]?.route;

    if (searchStep && nextRoute === searchStep.route) {
      const params = createSearchUrlFromBookingDeeplink(
        query,
        passengerRules,
        steps,
      );

      await push({
        keepPreviousQuery: false,
        query: parseQueryStringToObject(params?.split('?')[1]),
        pathname: searchStep.route,
      });
    } else {
      await push({
        pathname: nextRoute,
        query: values?.query,
      });
    }

    setInTransition({
      next: false,
      previous: false,
    });
  };

  useEffect(() => {
    const nextStep = filteredSteps[activeStepIndex + 1]?.route;

    if (activeStepIndex !== filteredSteps.length - 1 && nextStep) {
      prefetch(nextStep, undefined, { isSsg: true });
    }

    const previousStep =
      activeStepIndex !== 0
        ? filteredSteps[activeStepIndex - 1]?.route
        : undefined;

    if (previousStep) {
      prefetch(previousStep, undefined, { isSsg: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  return (
    <context.Provider
      value={{
        activeStepIndex,
        steps: filteredSteps,
        triggerBookingFlowStepTransition,
        inTransition,
        setInTransition,
        isLastStep: activeStepIndex === filteredSteps.length - 1,
        firstBookingStepIndex,
        shouldShowSeatmap,
        isFirstBookingStep: activeStepIndex === firstBookingStepIndex,
        searchStep,
      }}
    >
      {children}
    </context.Provider>
  );
};

export const useStep = () => useContext(context);
